<template>

    <div class="rankingList">
        <img @click="close" src="../../../assets/imgs/NewYear/end.png" alt="">
        <div class="tit" v-if="isSeeIndex == 1">
            <h1 style="text-align: center;font-size: 25px;line-height: 80px;">活动排行</h1>
            <div style="width: 90%;display: flex;justify-content: center;margin:0 auto 25px ;">
                <div style="width: 33%;text-align: center;font-size: 24px;">活动排行</div>
                <div style="width: 33%;text-align: center;font-size: 24px;">角色昵称</div>
                <div style="width: 33%;text-align: center;font-size: 24px;">全部金元宝</div>
            </div>
            <div>
                <div v-for="(item, index) in ActivityList" :key="index"
                    style="width: 90%;display: flex;justify-content: center;margin: 15px auto;">
                    <div style="width: 33%;text-align: center;font-size: 19px;">NO.{{ item.rank }}</div>
                    <div style="width: 33%;text-align: center;font-size: 19px;">{{ item.usernick }}</div>
                    <div style="width: 33%;text-align: center;font-size: 19px;">{{ item.money }}</div>
                </div>
            </div>
        </div>
        <div class="tit" v-if="isSeeIndex == 2">

            <h1 style="text-align: center;font-size: 25px;line-height: 80px;">活动规则</h1>
            <div class="day">
                <p>活动时间</p>
                <p>2025年1月23日12点—2025年2月16日24点</p>
            </div>
            <div class="rule">
                <p>
                    1.玩家完成每日任务可获得新春红包，打开新春可获得随机数量金元宝。
                </p>
                <p>2.每日任务每天0点重置。</p>
                <p>3.活动结束时活动排行榜的排名前三的玩家将获得丰厚奖励，具体如下：<br>
                    &nbsp;&nbsp;&nbsp;&nbsp;第一名：活动称号—乙巳财神；财神的铂金宝箱X1；歌曲定制礼包X1<br>
                    &nbsp;&nbsp;&nbsp;&nbsp;第二名：活动称号—乙巳财神；财神的黄金宝箱X1<br>
                    &nbsp;&nbsp;&nbsp;&nbsp;第三名：活动称号—乙巳财神；财神的白银宝箱X1<br>
                </p>
                <p>温馨提示：<br>
                    &nbsp;&nbsp;&nbsp;&nbsp;1.获得财神的铂金宝箱的玩家可从当期幸运盲盒奖池中获取指定道具两件。<br>
                    &nbsp;&nbsp;&nbsp;&nbsp;2.获得财神的黄金宝箱的玩家可从当期幸运盲盒奖池中获取指定道具一件。<br>
                    &nbsp;&nbsp;&nbsp;&nbsp;3.获得财神的白银宝箱的玩家可从当期幸运盲盒旷世珍宝奖池中随机获取道具一件.<br>
                    &nbsp;&nbsp;&nbsp;&nbsp;4.歌曲定制礼包包含10首定制歌曲，由玩家提供歌单并制作。
                </p>
            </div>
        </div>

        <!-- <div class="tit" v-if="isSeeIndex == 3">
            <h1 style="text-align: center;font-size: 25px;line-height: 80px;">恭喜获得奖励</h1>
            <div
                style="height: 100px;width: 100%;margin: 50px auto;display: flex;justify-content:center;height: 100px;font-size: 25px;">
                <div style="margin-right: 50px;font-size: 40px;">恭喜获得:{{ SeeData.prizeName }}</div>
                <div style="font-size: 40px;">X{{ SeeData.prizeNum }}</div>
            </div>

            <div class="shezhi">
                <div class="recur" @click="recur"></div>
                <div class="end" @click="quit"></div>
            </div>
        </div> -->

    </div>
</template>

<script>
import axios from 'axios'
import Cookies from 'js-cookie'

export default {
    name: 'rankingList',
    props: {
        closeisSee: {
            type: Function,
            default: () => { },
            // required: true
        },
        isSeeIndex: {
            type: Number,
            default: 1,
            // required: true
        },

    },
    data() {
        return {
            ActivityList: [],
        }
    },
    created() {
        if (this.isSeeIndex == 1) {
            axios({
                method: 'post',
                url: "/activity/getActivityRank",
                headers: {
                    token: Cookies.get('wx_token'),
                },
                data: {
                    activityName: '2025spring_activity',
                    userId: Cookies.get('wx_userId'),      // userId
                    platForm: 'web',
                }

            }).then((res) => {
                if (res.data.state == 200) {
                    this.ActivityList = res.data.data.rankInfoList;

                } else {
                    this.$message.error(res.data.message)
                }
            }).catch(err => {
                this.$message.error(err.message)
            })
        }
    },
    methods: {
        close() {
            this.closeisSee();
        },

    }
}
</script>

<style scoped lang="less">
.rankingList {
    width: 100%;
    height: 100%;
    // background-color: aqua;
    background-image: url('../../../assets/imgs/NewYear/seebg.png');
    background-size: 100% 100%;
    position: relative;

    >img {
        position: absolute;
        right: -50px;
        top: -45px;
    }

    .tit {
        height: 100%;
        width: 100%;

        // font-size: 25px;
        // font-weight: bolder;
        // line-height: 100px;

        .rule {
            width: 80%;
            height: 100%;
            margin: 0 auto;
            // overflow-x:scroll;
            // overflow-y:hidden;

            p {
                line-height: 30px;
            }
        }


        .day {

            >p {
                margin-bottom: 3%;
                text-align: center;
                font-size: 20px;
                font-weight: bold;
                color: #ff0000;
            }


        }
    }
}
</style>