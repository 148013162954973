<template>
  <div class="homeWrap">
    <noticeWX></noticeWX>
    <!-- <div class="mainCom w1200 clearfix" :style="{backgroundImage: 'url('+bannerBg+')'}"> -->
    <div class="mainCom w1200 clearfix">
      <div class="mainComL fl">
        <menuWX></menuWX>
      </div>
      <div class="mainComR fr">
        <div class="downBtn" style="position: relative;">
          <!-- <img src="@/assets/imgs/home/bannerBg.png" alt="" style="position: absolute; top: -20px; left: 90px;"/> -->
          <img :src="downBtn" alt="" @click="goDownLoad" />
        </div>
        <div class="box1 clearfix mb20">
          <div class="banner medio fl">
            <video :poster="require('@/assets/imgs/home/videoCover.png')" controls width="100%" height="270px">
              <source src="https://download-wx.qtthtech.com/images/webvideo/wxol.mp4" type="video/mp4" />
              Sorry, your browser doesn't support embedded videos.
            </video>
          </div>
          <div class="banner blingBox fr">
            <el-carousel height="270px">
              <el-carousel-item v-for="(item, index) in bannerList" :key="index">
                <a v-if="item.links" :href="item.links" target="_blank"><img :src="item.imgUrl" /></a>
                <img v-else :src="item.imgUrl" @click="goDownLoad(item.links)" alt="" />
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
        <div class="box2 mb20">
          <div class="banner" @click="goNotice(item)" v-for="(item, index) in activityImgs" :key="index">
            <img class="pic" :src="item.imgUrl" alt="" />
          </div>
          <!-- <div class="banner" @click="goAccount"><img class="pic" :src="banner4" alt=""></div>
          <div class="banner" @click="goSongChart"><img class="pic" :src="banner5" alt=""></div> -->
          <div class="banner">
            <div class="bannerSix clearfix">
              <div class="picSmall fl" @click="goIntegralShop">
                <img :src="banner6" alt="" />
              </div>
              <div class="picSmall fl" @click="goCeremony">
                <img :src="banner7" alt="" />
              </div>
              <div class="picSmall fl" @click="goDaily">
                <img :src="banner8" alt="" />
              </div>
              <div class="picSmall fl" @click="goRecruit">
                <img :src="banner9" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div class="box3 mb20">
          <div class="newsWrap" :style="{ backgroundImage: 'url(' + newsBg + ')' }">
            <span class="more" @click="goNews">更多 &gt;</span>
            <div class="tit">
              <span v-for="(item, index) in newsTitle" :class="{ active: newsActive == index }"
                @click="tagChange(item, index)">{{ item.title }}</span>
            </div>
            <div class="consWrap">
              <div class="itemGroup bgWhite">
                <div class="item" v-for="(item, index) in newsCons" @click="goNewsDetail(item)">
                  <span>{{ item.title }}</span>
                  <span class="fr" v-if="index != 0">{{ item.pubDate }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="newsRWrap txtC" :style="{ backgroundImage: 'url(' + newsIconBg + ')' }">
            <a target="_blank" href="tencent://message/?uin=3003533756&Site=wx.qtthtech.com&Menu=yes">
              <img border="0" :src="newsIcon1" alt="点击这里给我发消息" title="点击这里给我发消息" />
            </a>
            <img :src="newsIcon2" alt="" @click="alertEmail" />
          </div>
        </div>
        <div class="box4 mb20">
          <div class="specialWrap bgWhite">
            <div class="mb5">
              <homeTitWX :title="'游戏特色'" :isMore="false"></homeTitWX>
            </div>
            <div class="mb5">
              <div class="homeTit" style="margin-top: 10px">
                <span class="tit" style="margin: 0 36px; font-size: 15px">基础知识</span>
                <span class="tit" style="margin: 0 36px; font-size: 15px">系统介绍</span>
                <span class="tit" style="margin: 0 36px; font-size: 15px">系统模式</span>
              </div>
            </div>
            <div class="conWrap clearfix">
              <div class="itemGroup">
                <div class="item" :style="{ backgroundImage: 'url(' + specialBg + ')' }" @click="goDetail(2)">
                  人物信息
                </div>
                <div class="item" :style="{ backgroundImage: 'url(' + specialBg + ')' }" @click="goDetail(1)">
                  情侣系统
                </div>
                <div class="item" :style="{ backgroundImage: 'url(' + specialBg + ')' }" @click="goDetail(0)">
                  经典模式
                </div>
                <div class="item" :style="{ backgroundImage: 'url(' + specialBg + ')' }" @click="goDetail(2)">
                  如何跳舞
                </div>
                <div class="item" :style="{ backgroundImage: 'url(' + specialBg + ')' }" @click="goDetail(1)">
                  家族系统
                </div>
                <div class="item" :style="{ backgroundImage: 'url(' + specialBg + ')' }" @click="goDetail(0)">
                  新同步模式
                </div>
                <div class="item" :style="{ backgroundImage: 'url(' + specialBg + ')' }" @click="goDetail(2)">
                  如何升级
                </div>
                <div class="item" :style="{ backgroundImage: 'url(' + specialBg + ')' }" @click="goDetail(1)">
                  伙伴系统
                </div>
                <div class="item" :style="{ backgroundImage: 'url(' + specialBg + ')' }" @click="goDetail(0)">
                  家族战
                </div>
              </div>
            </div>
          </div>
          <div class="hallOfFameWrap" :style="{ backgroundImage: 'url(' + hallOfFameBg + ')' }">
            <div class="hallOfFame">
              <div class="mb10">
                <homeTitWX :title="'名人堂'" :type="'HallOfFame'" :isMore="true"></homeTitWX>
              </div>
              <table v-if="hallOfFameData.length > 0">
                <thead>
                  <tr>
                    <td width="60">期数</td>
                    <td width="100">特权公告名人</td>
                    <td width="100">霸屏公告名人</td>
                    <td width="100">超级公告名人</td>
                    <td width="100">普通公告名人</td>
                    <td width="80">赠花名人</td>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in hallOfFameData">
                    <td>{{ item.periodName }}</td>
                    <td v-for="(item2, index2) in item.usernickInfo" v-if="index2 < 5">
                      <template>{{ item2 }}</template>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div v-else class="noData" style="height: 236px">
                <img style="padding-top: 28px" :src="noData" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div class="box5 mb20">
          <div class="rankWrap" :style="{ backgroundImage: 'url(' + rankBg + ')' }">
            <div class="rank">
              <div class="mb5">
                <homeTitWX :title="'游戏排行'" :type="'Rank'" :isMore="true"></homeTitWX>
              </div>
              <div class="mb10">
                <homeTit2WX :title="[
                  '充值排行',
                  '特权排行',
                  '霸屏排行',
                  '超级排行',
                  '普通排行',
                  '赠花排行',
                  '魅力排行',
                  '泡点排行',
                  'VIP排行',
                  '家族排行',
                ]" :type="'rank'" @chageTag="chageTag"></homeTit2WX>
              </div>
              <table v-if="rankData.length > 0">
                <thead>
                  <tr>
                    <td v-for="item in rankDataHead[rankDataActive]">
                      {{ item }}
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in rankData">
                    <td>{{ item.field1 }}</td>
                    <td>{{ item.field2 }}</td>
                    <td>{{ item.field3 }}</td>
                    <td>{{ item.field4 }}</td>
                    <td>{{ item.field5 }}</td>
                    <td v-if="rankDataActive != 0 && rankDataActive != 9">
                      {{ item.field6 }}
                    </td>
                  </tr>
                </tbody>
              </table>
              <div v-else class="noData"><img :src="noData" alt="" /></div>
            </div>
          </div>
        </div>
        <div class="box6 bgWhite">
          <div class="specialWrap">
            <div class="mb5">
              <homeTitWX :title="'精彩瞬间'" :isMore="false"></homeTitWX>
            </div>
            <div class="mb10">
              <homeTit2WX :title="['游戏截图', '玩家风采', '官方图片']" :type="'special'" @chageTag="chageTag"></homeTit2WX>
            </div>
            <div class="consWrap">
              <div style="
                  position: relative;
                  z-index: 0;
                  width: 930px;
                  height: 200px;
                ">
                <div style="
                    position: absolute;
                    z-index: 0;
                    width: 932px;
                    height: 200px;
                    background: #fff;
                  " :style="{ zIndex: zIndex1 }">
                  <div class="swiper-container swiper-container0">
                    <div class="swiper-wrapper">
                      <!-- 这里的数据需要使用自己定义的数组或者从后端返回的数组 -->
                      <div class="swiper-slide swiper-no-swiping" v-for="item in game0" :key="item">
                        <img :src="item" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
                <div style="
                    position: absolute;
                    z-index: 0;
                    width: 932px;
                    height: 200px;
                    background: #fff;
                  " :style="{ zIndex: zIndex2 }">
                  <div class="swiper-container swiper-container1">
                    <div class="swiper-wrapper swiper-wrapper1">
                      <!-- 这里的数据需要使用自己定义的数组或者从后端返回的数组 -->
                      <div class="swiper-slide swiper-no-swiping swiper-slide1" v-for="item in game1" :key="item.id">
                        <img :src="item" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
                <div style="
                    position: absolute;
                    z-index: 0;
                    width: 932px;
                    height: 200px;
                    background: #fff;
                  " :style="{ zIndex: zIndex3 }">
                  <div class="swiper-container swiper-container2">
                    <div class="swiper-wrapper swiper-wrapper2">
                      <!-- 这里的数据需要使用自己定义的数组或者从后端返回的数组 -->
                      <div class="swiper-slide swiper-no-swiping swiper-slide2" v-for="item in game2" :key="item.id">
                        <img :src="item" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 活动 -->
        <!-- <div style="position: absolute;height: 300px;top: 88%; left: 36%;">
          <img src="@/assets/imgs/home/bannerBg.png" alt="">
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import noticeWX from "./notice";
import homeTitWX from "./homeTit";
import homeTit2WX from "./homeTit2";
import menuWX from "@/components/Menu";
import { publicUrl } from "@/common/publicUrl.js";
import Cookies from "js-cookie";
import Swiper from "swiper"; // 导入Swiper库

import axios from "axios";
export default {
  name: "home",
  components: {
    noticeWX,
    homeTitWX,
    homeTit2WX,
    menuWX,
  },
  data() {
    return {
      noData: require("@/assets/imgs/home/noData.png"),
      bannerBg: require("@/assets/imgs/home/bannerBg.png"),
      downBtn: require("@/assets/imgs/home/downBtn.png"),
      banner1: require("@/assets/imgs/home/banner2.png"),
      banner2: require("@/assets/imgs/home/banner2.png"),
      banner3: require("@/assets/imgs/home/banner3.png"),
      banner4: require("@/assets/imgs/home/banner4.png"),
      banner5: require("@/assets/imgs/home/banner5.png"),
      banner6: require("@/assets/imgs/home/banner6.png"),
      banner7: require("@/assets/imgs/home/banner7.png"),
      banner8: require("@/assets/imgs/home/banner8.png"),
      banner9: require("@/assets/imgs/home/banner9.png"),
      banner10: require("@/assets/imgs/home/banner10.png"),
      bannerList: [], // 轮播
      activityImgs: [], // 活动图片
      newsActive: 0,
      newsTitle: [
        { catId: 0, title: "全部" },
        { catId: 1, title: "新闻" },
        { catId: 2, title: "活动" },
        { catId: 3, title: "公告" },
      ],
      newsCons: [], // 新闻列表数据
      newsBg: require("@/assets/imgs/home/newsBg.png"),
      newsIconBg: require("@/assets/imgs/home/newsIconBg.png"),
      newsIcon1: require("@/assets/imgs/home/newsIcon1.png"),
      newsIcon2: require("@/assets/imgs/home/newsIcon2.png"),
      specialBg: require("@/assets/imgs/home/specialBg.png"),

      hallOfFameBg: require("@/assets/imgs/home/hallOfFameBg.png"), // 名人堂相关
      hallOfFameData: [],

      rankBg: require("@/assets/imgs/home/rankBg.png"),
      rankDataActive: 0,
      rankDataType: [
        { id: 1, name: "充值排行" },
        { id: 2, name: "特权排行" },
        { id: 3, name: "霸屏排行" },
        { id: 4, name: "超级排行" },
        { id: 5, name: "普通排行" },
        { id: 6, name: "赠花排行" },
        { id: 7, name: "魅力排行" },
        { id: 8, name: "泡点排行" },
        { id: 9, name: "VIP排行" },
        { id: 10, name: "家族排行" },
      ],
      rankDataHead: [
        ["排名", "角色昵称", "情侣昵称", "等级", "家族"], // 充值排行榜
        ["排名", "角色昵称", "情侣昵称", "等级", "家族", "发送次数"], // 特权排行榜
        ["排名", "角色昵称", "情侣昵称", "等级", "家族", "发送次数"], // 霸屏排行榜
        ["排名", "角色昵称", "情侣昵称", "等级", "家族", "发送次数"], // 超级排行榜
        ["排名", "角色昵称", "情侣昵称", "等级", "家族", "发送次数"], // 普通排行榜
        ["排名", "角色昵称", "情侣昵称", "等级", "家族", "赠送鲜花数量"], // 赠花排行榜
        ["排名", "角色昵称", "情侣昵称", "等级", "家族", "魅力值"], // 魅力排行榜
        ["排名", "角色昵称", "情侣昵称", "等级", "家族", "泡点"], // 泡点排行榜
        ["排名", "角色昵称", "情侣昵称", "等级", "家族", "累计获取积分"], // VIP排行榜
        ["排名", "家族名称", "家族族长昵称", "家族人数", "综合指数"], // 家族排行
      ],
      rankData: [],
      zIndex1: 3,
      zIndex2: 2,
      zIndex3: 1,
      game0: [
        require("@/assets/imgs/home/game0/0.png"),
        require("@/assets/imgs/home/game0/1.png"),
        require("@/assets/imgs/home/game0/2.png"),
        require("@/assets/imgs/home/game0/3.png"),
        require("@/assets/imgs/home/game0/4.png"),
        require("@/assets/imgs/home/game0/5.png"),
        require("@/assets/imgs/home/game0/6.png"),
        require("@/assets/imgs/home/game0/7.png"),
        require("@/assets/imgs/home/game0/8.png"),
        require("@/assets/imgs/home/game0/9.png"),
      ],
      game1: [
        require("@/assets/imgs/home/game1/0.png"),
        require("@/assets/imgs/home/game1/1.png"),
        require("@/assets/imgs/home/game1/2.png"),
        require("@/assets/imgs/home/game1/3.png"),
        require("@/assets/imgs/home/game1/4.png"),
        require("@/assets/imgs/home/game1/5.png"),
        require("@/assets/imgs/home/game1/6.png"),
        require("@/assets/imgs/home/game1/7.png"),
        require("@/assets/imgs/home/game1/8.png"),
        require("@/assets/imgs/home/game1/9.png"),
        require("@/assets/imgs/home/game1/10.png"),
        require("@/assets/imgs/home/game1/11.png"),
        require("@/assets/imgs/home/game1/12.png"),
        require("@/assets/imgs/home/game1/13.png"),
        require("@/assets/imgs/home/game1/14.png"),
        require("@/assets/imgs/home/game1/15.png"),
        require("@/assets/imgs/home/game1/16.png"),
        require("@/assets/imgs/home/game1/17.png"),
        require("@/assets/imgs/home/game1/18.png"),
        require("@/assets/imgs/home/game1/19.png"),
        require("@/assets/imgs/home/game1/20.png"),
      ],
      game2: [
        require("@/assets/imgs/home/game2/0.png"),
        require("@/assets/imgs/home/game2/1.png"),
        require("@/assets/imgs/home/game2/2.png"),
        require("@/assets/imgs/home/game2/3.png"),
        require("@/assets/imgs/home/game2/4.png"),
      ],

      specialGameActive: 0,
      specialGameList: [
        ["人物信息", "如何跳舞", "如何升级"],
        ["情侣系统", "家族系统", "伙伴系统"],
        ["经典模式", "新同步模式", "家族战"],
      ],
    };
  },
  created() {
    this.special2Icon = this.game0;
    this.getNewsData();
    this.getHallOfFameData(); // 获取名人堂数据
    this.getRankData(1); // 排行
    this.getBannerList();
  },
  mounted() {
    document.documentElement.scrollTop = 0;
    this.$nextTick(() => {
      this.initSwiper();
      this.initSwiper1();
      this.initSwiper2();
    });
  },
  methods: {
    /* banner轮播 */
    getBannerList() {
      axios
        .get("/common/getBannerList")
        .then((res) => {
          if (res.data.state == 200) {
            this.bannerList = res.data.data.list;
            this.activityImgs = res.data.data.activityImgs.reverse();
          } else {
            this.$message.error(res.data.message);
          }
        })
        .catch((err) => {
          this.$message.error(err.message);
        });
    },
    /* 精彩瞬间 */
    chageTag(data) {
      if (data.type == "special") {
        if (data.ind == 0) {
          this.zIndex1 = 4;
          this.zIndex2 = 2;
          this.zIndex3 = 3;
        } else if (data.ind == 1) {
          this.zIndex1 = 1;
          this.zIndex2 = 4;
          this.zIndex3 = 3;
        } else if (data.ind == 2) {
          this.zIndex1 = 1;
          this.zIndex2 = 2;
          this.zIndex3 = 4;
        }
      } else if (data.type == "rank") {
        this.rankDataActive = data.ind;
        let item2 = data.item;
        let ind = this.rankDataType.filter((item) => {
          return item.name == item2;
        })[0].id;
        console.log(ind);
        this.getRankData(ind);
      }
    },
    initSwiper() {
      new Swiper(".swiper-container0", {
        autoplay: {
          delay: 3000, //1秒切换一次
        }, //等同于以下设置

        loop: true,
        slidesPerView: 4, //显示4个
        speed: 1000,
        initialSlide: 0,
      });
    },
    initSwiper1() {
      new Swiper(".swiper-container1", {
        autoplay: {
          delay: 3000, //1秒切换一次
        }, //等同于以下设置
        delay: 8000, //1秒切换一次
        loop: true,
        slidesPerView: 4, //显示4个
        speed: 1000,
        initialSlide: 0,
      });
    },
    initSwiper2() {
      new Swiper(".swiper-container2", {
        autoplay: {
          delay: 3000, //1秒切换一次
        }, //等同于以下设置
        delay: 8000, //1秒切换一次
        loop: true,
        slidesPerView: 4, //显示4个
        speed: 1000,
        initialSlide: 0,
      });
    },

    /* 新闻相关 */
    tagChange(item, index) {
      // 点击新闻tag
      this.newsActive = index;
      this.getNewsData();
    },
    getNewsData() {
      // 获取初始数据
      let data = {
        catId: this.newsTitle[this.newsActive].catId,
        types: "home",
      };
      axios
        .post("/news/getNewslist", data)
        .then((res) => {
          if (res.data.state == 200) {
            if (res.data.data.list) {
              if (res.data.data.list.length >= 5) {
                this.newsCons = res.data.data.list.slice(0, 5);
              } else {
                this.newsCons = res.data.data.list;
              }
            }
          } else {
            this.$message.error(res.data.message);
          }
        })
        .catch((err) => {
          this.$message.error(err.message);
        });
    },
    goDetail(type) {
      this.$router.push("/gameDetail?type=" + type);
    },

    /* 名人堂相关*/
    getHallOfFameData() {
      axios
        .post("/rank/getIndexFamousRank")
        .then((res) => {
          if (res.data.state == 200) {
            if (res.data.data.famousResponses) {
              this.hallOfFameData = res.data.data.famousResponses.splice(0, 7);
            }
          } else {
            this.$message.error(res.data.message);
          }
        })
        .catch((err) => {
          this.$message.error(err.message);
        });
    },

    /* 游戏排行相关*/
    getRankData(type) {
      let data = {
        type: type,
      };
      axios
        .post("/rank/getWebRankList", data)
        .then((res) => {
          if (res.data.state == 200) {
            if (res.data.data.rankOneList) {
              this.rankData = res.data.data.rankOneList.splice(0, 5);
            }
          } else {
            this.$message.error(res.data.message);
          }
        })
        .catch((err) => {
          this.$message.error(err.message);
        });
    },

    /*************************** 跳转相关 */
    goNewsDetail(item) {
      // 跳转到新闻详情
      this.$router.push({
        path: "/newsDetail",
        query: {
          newsId: item.newsId,
        },
      });
    },
    goNews() {
      // 跳转到新闻页面
      this.$router.push("/news");
    },
    goRecruit() {
      // 跳转到招募页面
      this.$router.push("/recruit");
    },
    goDaily() {
      // 跳转到日常页面
      this.$router.push("/daily");
    },
    goCeremony() {
      // 跳转到礼遇页面
      this.$router.push("/ceremony");
    },
    goIntegralShop() {
      // window.open(publicUrl.shopIntegralUrl, '_blank');    // 商城// 跳转到积分商城
      this.$router.push("/exchange"); // 跳转到兑换中心页面
    },
    // goSongChart() {
    //   // 跳转到歌曲打榜
    //   // this.$message.error('暂时还没有数据哦！')
    //   // return false
    //   // this.$router.push('/songChart')  // 跳转到歌曲打榜页面
    //   // this.$router.push('/chidrenDay')  // 跳转到欢乐六一页面
    //   // this.$router.push('/duanwu')  // 跳转到端午活动页面
    //   this.$router.push("/dancing"); // 跳转到自由舞会页面
    //   // this.$router.push('/qixi')  // 跳转到七夕
    //   // this.$router.push('/throwinggame')  // 跳转到骰子
    // },
    // goAccount() {
    //   // 跳转到个人中心 - 泡点系统
    //   this.$router.push({
    //     path: "/account",
    //     query: {
    //       type: 2,
    //     },
    //   });
    //   // this.$router.push('/sixoneeight')  // 跳转到618活动页面
    //   // this.$router.push('/throwinggame')  // 跳转到骰子
    // },
    goNotice(item) {
      console.log(item,'666',this.activityImgs);
      
      // 跳转活动图片
      if (item.links == '/') {
        this.$message.error('暂时还没有数据哦！')
      } else if (item.links.indexOf("?") == -1) {
        this.$router.push(item.links);
      } else {
        this.$router.push({
          path: "/account",
          query: {
            type: 2,
          },
        });
      }

      // this.$router.push('/notice')
    },
    goBlindBox() {
      // 跳转商城盲盒页面
      window.open(publicUrl.shopBlindBoxUrl, "_blank"); // 商城
    },
    goDownLoad() {
      // 跳转到下载中心
      this.$router.push("/downLoad");
    },
    alertEmail() {
      this.$message.warning("service@qtthtech.com");
    },
    // godemo(){
    //   window.open("https://openapi.alipay.com/gateway.do?alipay_sdk=alipay-sdk-PHP-4.11.14.ALL&app_id=2021004123604482&biz_content=%7B%22out_trade_no%22%3A%22wx9i17320240605175101447708%22%2C%22total_amount%22%3A0.01%2C%22subject%22%3A%22%E6%B8%B8%E6%88%8F%E5%85%85%E5%80%BC%22%2C%22timeout_express%22%3A%2290m%22%2C%22product_code%22%3A%22FAST_INSTANT_TRADE_PAY%22%7D&charset=utf-8&format=json&method=alipay.trade.page.pay&notify_url=http%3A%2F%2Fceshi.9i173.com%2FNotify%2Falipay&sign=NojwEEBeEi3Hx9prQQGny8tbGNVl87hlMn0FG%2FABeFW1Ukd%2FmhWXXtGvCdYnXRxBO%2BM6D%2FbUA9qE%2BkljI1Fy4AnP6Ei6srC5obwkWUlQFPoAftPcdX0RT6NuQ01nf62qRKQYb2%2BgqEi%2Bqea5ijAmP0Tzf2xoHbj2mPQpjRLAh6v9EalAKbRZ59%2FvnOO067l94ziTRlpnc5lb4orI95N%2B0glGmgCK%2BDHa77qqFy%2F%2FvxqaTDYXUudI5IINqoYEvbAy7x1nlly8Yll3Nmaa2GK%2FNSVi4HkjYLAYBLjga2u%2BtlCnzisJmvVg54JfihU7g9vPcrrqHY6y8tWmZ5t3TS1hjg%3D%3D&sign_type=RSA2&timestamp=2024-06-05+17%3A51%3A23&version=1.0")
    // }
  },
};
</script>
<style lang="less" scoped>
.homeWrap {
  padding-bottom: 40px;

  .mainCom {
    padding-top: 345px;
    background-repeat: no-repeat;
    background-position: center 337px;

    .mainComL {
      padding-top: 124px;
    }

    .mainComR {
      .noData {
        text-align: center;
      }

      .downBtn {
        text-align: right;
        cursor: pointer;
      }

      .box1 {
        .banner {
          width: 470px;
          height: 270px;

          img {
            width: 100%;
          }
        }

        .medio {}

        .blingBox {
          cursor: pointer;
        }
      }

      .box2 {
        display: flex;
        margin-right: -20px;

        .banner {
          margin-right: 12px;
          width: 228px;
          height: 228px;
          overflow: hidden;

          .pic {
            width: 100%;

            &:hover {
              cursor: pointer;
            }
          }

          .bannerSix {
            margin-right: -20px;

            .picSmall {
              margin-right: 10px;
              width: 112px;
              height: 120px;
              overflow: hidden;

              img {
                width: 100%;

                &:hover {
                  cursor: pointer;
                }
              }
            }
          }

          &:last-child {
            margin-top: -12px;
            height: 240px;
          }
        }
      }

      .box3 {
        display: flex;

        .newsWrap {
          position: relative;
          z-index: 1;
          margin-right: 10px;
          min-width: 790px;
          width: 790px;
          background-repeat: no-repeat;

          .more {
            position: absolute;
            right: 15px;
            top: 0;
            z-index: 1;
            line-height: 40px;
            cursor: pointer;
          }

          .tit {
            height: 40px;
            line-height: 44px;

            span {
              margin: 0 100px 0 20px;
              font-size: 16px;
              cursor: pointer;
            }

            .active {
              padding: 2px;
              font-weight: 700;
              border-bottom: 2px solid #333333;
            }
          }

          .consWrap {
            padding: 15px 20px;

            .itemGroup {
              padding: 10px 20px;
              height: 230px;
              box-sizing: border-box;
              border-radius: 6px 6px 6px 6px;

              .item {
                padding: 12px 0;
                border-bottom: 1px dashed #999999;
                cursor: pointer;

                span {
                  font-size: 16px;
                }

                &:first-child {
                  span {
                    font-size: 18px;
                    color: #d22190;
                  }
                }

                &:last-child {
                  border-bottom: none;
                }

                &:hover {
                  color: #d22190;
                }
              }
            }
          }
        }

        .newsRWrap {
          padding: 188px 0 10px;
          background-repeat: no-repeat;

          img {
            cursor: pointer;
          }
        }
      }

      .box4 {
        display: flex;
        justify-content: space-between;

        .specialWrap {
          padding: 20px 20px 0 20px;
          width: 440px;
          min-width: 440px;
          box-sizing: border-box;

          .conWrap {
            border-top: 1px dashed #999999;

            .itemGroup {
              margin-right: -20px;

              .item {
                float: left;
                display: inline-block;
                padding: 30px 0;
                text-align: center;
                width: 120px;
                height: 60px;
                margin: 0 18px 8px 0;
                background-repeat: no-repeat;
                background-size: 100% 100%;
                box-sizing: border-box;
                color: #ffffff;
                cursor: pointer;
              }
            }
          }
        }

        .hallOfFameWrap {
          padding: 10px;
          width: 500px;
          height: 296px;
          box-sizing: border-box;
          background-repeat: no-repeat;

          .hallOfFame {
            padding: 10px;
            border-radius: 6px;
            background-color: rgba(255, 255, 255, 0.8);

            table {
              border: 1px solid #555555;

              tr {
                &:nth-child(2n) {
                  background: rgba(85, 85, 85, 0.1);
                }
              }

              td {
                padding: 8px 5px;
                border-right: 1px solid #555555;
                border-bottom: 1px solid #555555;
                text-align: center;
                font-size: 12px;
                line-height: 11px;
              }

              thead {
                td {
                  color: #d22190;
                }
              }
            }
          }
        }
      }

      .box5 {
        .rankWrap {
          padding: 10px;

          .rank {
            padding: 10px;
            height: 255px;
            border-radius: 6px;
            background: rgba(255, 255, 255, 0.8);

            table {
              width: 100%;
              border: 1px solid #555555;

              tr {
                &:nth-child(2n) {
                  background: rgba(255, 255, 255, 0.8);
                }
              }

              td {
                padding: 8px 5px;
                border-right: 1px solid #555555;
                border-bottom: 1px solid #555555;
                text-align: center;
                font-size: 12px;
              }

              thead {
                td {
                  color: #d22190;
                }
              }
            }
          }
        }
      }

      .box6 {
        .specialWrap {
          padding: 10px;

          .consWrap {
            .cons {
              display: flex;
              justify-content: space-around;
            }
          }
        }
      }
    }
  }
}
</style>
