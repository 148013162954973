import Vue from "vue";
import VueRouter from "vue-router";
import store from '../store/store'
const routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch((error) => error);
};
import error from "../components/error";
// 1.安装插件
Vue.use(VueRouter);

// 2.创建router
const router = new VueRouter({
  // mode: "history", // hash  history
  base: "/",
  routes: [
    {
      path: "/",
      redirect: "/main",
      name: "Main",
      component: (resolve) => require(["@/views/main/index"], resolve),
      children: [
        {
          path: "/",
          name: "Home",
          component: (resolve) => require(["@/views/home/index"], resolve),
          meta: {
            title: "舞侠 - 首页",
            regist: true,
            login: true,
            download: true,
            enter: true,
            ad: true,
            label: true,
            config: true,
            isMustLogin: false,
          },
        },
        {
          path: "/register",
          name: "Register",
          component: (resolve) => require(["@/views/register/index"], resolve),
          meta: {
            title: "舞侠 - 注册",
            download: true,
            enter: true,
            ad: true,
            label: true,
            config: true,
            isMustLogin: false,
          },
        },
        {
          path: "/login",
          name: "Login",
          component: (resolve) => require(["@/views/login/index"], resolve),
          meta: {
            title: "舞侠 - 登录",
            download: true,
            enter: true,
            ad: true,
            label: true,
            config: true,
            isMustLogin: false,
          },
        },
        {
          path: "/downLoad",
          name: "DownLoad",
          component: (resolve) => require(["@/views/downLoad/index"], resolve),
          meta: {
            title: "舞侠 - 下载中心",
            download: true,
            enter: true,
            ad: true,
            label: true,
            config: true,
            isMustLogin: false,
          },
        },
        {
          path: "/AppdownLoad",
          name: "AppDownLoad",
          component: (resolve) => require(["@/views/downLoad/load"], resolve),
          meta: {
            title: "舞侠 - 下载中心",
            download: true,
            enter: true,
            ad: true,
            label: true,
            config: true,
            isMustLogin: false,
          },
        },
        {
          path: "/account",
          name: "Account",
          component: (resolve) => require(["@/views/account/index"], resolve),
          meta: {
            title: "舞侠 - 账户中心",
            login: true,
            download: true,
            enter: true,
            record: true,
            ad: true,
            label: true,
            config: true,
            isMustLogin: true,
          },
        },
        {
          path: "/notice",
          name: "Notice",
          component: (resolve) => require(["@/views/notice/index"], resolve),
          meta: {
            title: "舞侠 - 公告",
            download: true,
            enter: true,
            record: true,
            ad: true,
            notice: true,
            isMustLogin: true,
          },
        },
        {
          path: "/ceremony",
          name: "Ceremony",
          component: (resolve) => require(["@/views/ceremony/index"], resolve),
          meta: {
            title: "舞侠 - 礼遇",
            login: true,
            download: true,
            enter: true,
            record: true,
            ad: true,
            isMustLogin: true,
          },
        },
        // {
        //   path: "/chidrenDay",
        //   name: "ChidrenDay",
        //   component: (resolve) => require(["@/views/ChildrenDay/index"], resolve),
        //   meta: {
        //     title: "舞侠 - 欢乐六一",
        //     login: true,
        //     download: true,
        //     enter: true,
        //     record: true,
        //     ad: true,
        //     isMustLogin: true,
        //   },
        // },
        {
          path: "/daily",
          name: "Daily",
          component: (resolve) => require(["@/views/daily/index"], resolve),
          meta: {
            title: "舞侠 - 日常",
            login: true,
            download: true,
            enter: true,
            record: true,
            ad: true,
            isMustLogin: true,
          },
        },
        {
          path: "/news",
          name: "NewsList",
          component: (resolve) => require(["@/views/news/list"], resolve),
          meta: {
            title: "舞侠 - 新闻列表",
            login: true,
            download: true,
            enter: true,
            record: true,
            ad: true,
            isMustLogin: false,
          },
        },
        {
          path: "/newsDetail",
          name: "NewsDetail",
          component: (resolve) => require(["@/views/news/detail"], resolve),
          meta: {
            title: "舞侠 - 新闻详情",
            login: true,
            download: true,
            enter: true,
            record: true,
            ad: true,
            isMustLogin: false,
          },
        },
        {
          path: "/gameDetail",
          name: "GameDetail",
          component: (resolve) => require(["@/views/news/gameDetail"], resolve),
          meta: {
            title: "舞侠 - 新闻详情",
            login: true,
            download: true,
            enter: true,
            record: true,
            ad: true,
            isMustLogin: false,
          },
        },
        {
          path: "/recruit",
          name: "Recruit",
          component: (resolve) => require(["@/views/recruit/index"], resolve),
          meta: {
            title: "舞侠 - 招募",
            login: true,
            download: true,
            enter: true,
            record: true,
            ad: true,
            isMustLogin: true,
          },
        },
        {
          path: "/hallOfFame",
          name: "HallOfFame",
          component: (resolve) =>
            require(["@/views/hallOfFame/index"], resolve),
          meta: {
            title: "舞侠 - 名人堂",
            dance: true,
            bgm: true,
            hiddenRainbow: true,
            isMustLogin: true,
          },
        },
        {
          path: "/rank",
          name: "Rank",
          component: (resolve) => require(["@/views/rank/index"], resolve),
          meta: {
            title: "舞侠 - 热榜",
            dance: true,
            bgm: true,
            hiddenRainbow: true,
            isMustLogin: true,
          },
        },
        {
          path: "/record",
          name: "Record",
          component: (resolve) => require(["@/views/record/index"], resolve),
          meta: {
            title: "舞侠 - 记录",
            login: true,
            download: true,
            enter: true,
            record: true,
            ad: true,
            label: true,
            config: true,
            //rank:true,
            isMustLogin: true,
          },
        },
        {
          path: "/recharge",
          name: "Recharge",
          component: (resolve) => require(["@/views/recharge/index"], resolve),
          meta: {
            title: "舞侠 - 充值中心",
            login: true,
            download: true,
            enter: true,
            record: true,
            ad: true,
            label: true,
            config: true,
            rank: true,
            isMustLogin: true,
          },
        },
        {
          path: "/songChart",
          name: "SongChart",
          component: (resolve) => require(["@/views/songChart/index"], resolve),
          meta: {
            title: "舞侠 - 歌曲打榜",
            login: true,
            download: true,
            enter: true,
            record: true,
            ad: true,
            bgm: true,
            isMustLogin: true,
          },
        },
      ],
    },
    {
      path: "/demo",
      name: "Demo",
      component: (resolve) => require(["@/views/demo/index"], resolve),
      meta: {
        title: "demo",
      },
    },
    // {
    //   path: "/midAutumn",
    //   name: "midAutumn",
    //   component: (resolve) => require(["@/views/DragonBoatFestival/index"], resolve),
    //   meta: {
    //     title: "舞侠 - 中秋",
    //     login: true,
    //     download: true,
    //     enter: true,
    //     record: true,
    //     ad: true,
    //     bgm: true,
    //     isMustLogin: true,
        
    //   },
    // },


    // {
    //   path: "/DoubleEleven",
    //   name: "DoubleEleven",
    //   component: (resolve) => require(["@/views/DoubleEleven/index"], resolve),
    //   meta: {
    //     title: "舞侠 - 双十一",
    //     login: true,
    //     download: true,
    //     enter: true,
    //     record: true,
    //     ad: true,
    //     bgm: true,
    //     isMustLogin: true,
        
    //   },
    // },
    
    // {
    //   path: "/qixi",
    //   name: "qixi",
    //   component: (resolve) => require(["@/views/Qixi/index"], resolve),
    //   meta: {
    //     title: "舞侠 - 七夕",
    //     login: true,
    //     download: true,
    //     enter: true,
    //     record: true,
    //     ad: true,
    //     bgm: true,
    //     isMustLogin: true,
        
    //   },
    // },
    // {
    //   path: "/NationalDay",
    //   name: "NationalDay",
    //   component: (resolve) => require(["@/views/NationalDay/index"], resolve),
    //   meta: {
    //     title: "舞侠 - 国庆",
    //     login: true,
    //     download: true,
    //     enter: true,
    //     record: true,
    //     ad: true,
    //     bgm: true,
    //     isMustLogin: true,
        
    //   },
    // },

    // {
    //   path: "/Christmas",
    //   name: "Christmas",
    //   component: (resolve) => require(["@/views/Christmas/index"], resolve),
    //   meta: {
    //     title: "舞侠 - 双旦",
    //     login: true,
    //     download: true,
    //     enter: true,
    //     record: true,
    //     ad: true,
    //     bgm: true,
    //     isMustLogin: true,
        
    //   },
    // },

    {
      path: "/exchange",
      name: "Exchange",
      component: (resolve) => require(["@/views/Exchange/index"], resolve),
      meta: {
        title: "舞侠 - 礼包兑换",
        login: true,
        download: true,
        enter: true,
        record: true,
        ad: true,
        bgm: true,
        isMustLogin: true,
        
      },
    },
    // {
    //   path: "/throwinggame",
    //   name: "ThrowingGame",
    //   component: (resolve) => require(["@/views/ThrowingGame/index"], resolve),
    //   meta: {
    //     title: "舞侠 - 掷骰子",
    //     login: true,
    //     download: true,
    //     enter: true,
    //     record: true,
    //     ad: true,
    //     bgm: true,
    //     isMustLogin: true,
        
    //   },
    // },
    // {
    //   path: "/dancing",
    //   name: "Dancing",
    //   component: (resolve) => require(["@/views/FreedomDancing/index"], resolve),
    //   meta: {
    //     title: "舞侠 - 自由舞会",
    //     login: true,
    //     download: true,
    //     enter: true,
    //     record: true,
    //     ad: true,
    //     bgm: true,
    //     isMustLogin: true,
        
    //   },
    // },

    {
      path: "/NewYear",
      name: "NewYear",
      component: (resolve) => require(["@/views/NewYear/index"], resolve),
      meta: {
        title: "舞侠 - 春节活动",
        login: true,
        download: true,
        enter: true,
        record: true,
        ad: true,
        bgm: true,
        isMustLogin: true,
        
      },
    },
    {
      path: '/404',
      name: 'NotFound',
      meta: {
        title: 'Page not found',
        isLogin: false
      },
      component: error
    },
    // 所有未定义路由，全部重定向到404页，必须放在最后
    {
      path: '*',
      redirect: '/404'
    },
  ],
});
// router.beforeEach((to, from, next) => {
//   if (to.redirectedFrom == "/17173") {
   
//     // window.location.href = "https://www.baidu.com";
    
//     localStorage.setItem("17173", to.redirectedFrom); 
//      next({
//       path: '/'
//     })
//     console.log(to);
//     location.reload();
    
//     return;
//   }
//   document.title = to.meta.title; //切换路由改变页面标题
//   if (to.meta.isMustLogin) {
//     if (Cookies.get("wx_token")) {
//       next();
//     } else {
//       Vue.prototype.$message.error("请先登录！");
//       if (from.name == null) {
//         window.location.href = "https://wx.qtthtech.com";
        
//       }
//     }
//   } else {
//     if (to.name == "Account") {
//       if (Cookies.get("wx_token")) {
//         next();
//       } else {
//         location.href = publicUrl.loginPage;
//       }
//     } else if (to.name == "Register") {
//       if (Cookies.get("wx_token")) {
//         Vue.prototype.$message.error("请先退出当前账户");
//       } else {
//         next();
//       }
//     } else {
//       next();
//     }
//   }
// });
export default router;
