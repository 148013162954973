<template>
    <div class="container">
        <headerWX class="headerWX"></headerWX>
        <div class="content">
            <img class="bg" src="@/assets/imgs/NewYear/bg.png" alt="" />

            <div style="height: 120px;"></div>

            <!-- 每日任务 -->
            <div class="top">
                <img src="@/assets/imgs/NewYear/everyday.png" alt="" />

                <div class="topBady">
                    <div class="top_left">
                        <div class="item" v-for="(item, index) in getActivityList.dayActivityConfigList" :key="index">
                            <p>{{ item.actName }}<span> {{ item.processTxt }}</span></p>
                        </div>
                    </div>
                    <div class="top_right">
                        <div class="top_r_l">
                            <div class="item" v-for="(item, index) in getActivityList.dayActivityConfigList"
                                :key="index">
                                <img src="@/assets/imgs/NewYear/RedPacket_icon.png" alt="" />
                                <p>X {{ item.prizeNum }}</p>
                            </div>
                        </div>
                        <div class="top_r_r">
                            <div class="item" v-for="(item, index) in getActivityList.dayActivityConfigList"
                                :key="index">
                                <img v-if="item.btnText == '已领取'" src="@/assets/imgs/NewYear/AlreadyReceived.png"
                                    alt="" />
                                <img v-else-if="item.isClick == 1" @click="receive(item)"
                                    src="@/assets/imgs/NewYear/get_btn.png" alt="" />
                                <img v-else-if="item.isClick == 0 && item.btnUrl != ''" @click="go(item)"
                                    src="@/assets/imgs/NewYear/go.png" alt="" />
                                <img v-else-if="item.isClick == 0 && item.btnUrl == ''"
                                    src="@/assets/imgs/NewYear/get_btn.png" alt="" style="filter: grayscale(100%);" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- 我的新春红包 -->
            <div class="MyNewMoney">
                <div>
                    <img @click="go_See(1)" src="@/assets/imgs/NewYear/MyNewMoney_l.png" alt="">
                </div>
                <div class="NewYear_body">
                    <img src="@/assets/imgs/NewYear/MyNewMoney_c.png" alt="">
                    <div class="NewYear_item">
                        <div class="n_l">{{ totalData2.surplusNum }}</div>
                        <div class="n_c">{{ totalData2.useNum }}</div>
                        <div class="n_r">{{ totalData2.totalNum }}</div>
                    </div>
                </div>
                <div>
                    <img @click="go_See(2)" src="@/assets/imgs/NewYear/MyNewMoney_r.png" alt="">
                </div>
            </div>

            <!-- 红包弹窗 -->
            <div class="RedPacket">
                <img ref="RedPacket" src="@/assets/imgs/NewYear/RedPacket.png" alt="">
                <div @click="moneyMaskTrue" @mouseenter="handleMouseEnter" @mouseleave="handleMouseLeave"></div>
                <img class="RedPacket_hint" src="@/assets/imgs/NewYear/RedPacket_hint.png" alt="">
            </div>
            <!-- 挑战任务 -->
            <div class="upgrade">
                <div style="text-align: center;">
                    <img src="@/assets/imgs/NewYear/upgrade_title.png" alt="">

                </div>
                <div>
                    <img src="@/assets/imgs/NewYear/upgrade.png" alt="" />
                    <div class="upgradeBady">
                        <div class="c_l">
                            <div class="item" v-for="(item, index) in getActivityList.activityConfigList" :key="index">
                                {{ item.actName }}<span> {{ item.processTxt }}</span>
                            </div>
                        </div>
                        <div class="c_r">
                            <div class="c_r_l">
                                <div class="item" v-for="(item, index) in getActivityList.activityConfigList"
                                    :key="index">
                                    <div class="item_one">
                                        <img src="@/assets/imgs/NewYear/gold_icon.png" alt="" />
                                        <p>X {{ item.prizeNum }}</p>
                                    </div>

                                    <div v-for="(img, i) in item.prizeList" :key="i"
                                        style="display: flex;align-items: center;">
                                        <img :src="img.prizeImg" alt="">
                                        <p> X {{ img.prizeNum }}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="c_r_r">
                                <div class="item" v-for="(item, index) in getActivityList.activityConfigList"
                                    :key="index">
                                    <img v-if="item.btnText == '已领取'" src="@/assets/imgs/NewYear/AlreadyReceived.png"
                                        alt="" />
                                    <img v-else-if="item.isClick == 1" @click="receive(item)"
                                        src="@/assets/imgs/NewYear/get_btn.png" alt="" />
                                    <img v-else-if="item.isClick == 0 && item.btnUrl != ''" @click="go(item)"
                                        src="@/assets/imgs/NewYear/go.png" alt="" />
                                    <img v-else-if="item.isClick == 0 && item.btnUrl == ''"
                                        src="@/assets/imgs/NewYear/get_btn.png" alt=""
                                        style="filter: grayscale(100%);" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <!-- 元宝 剩余 总共 使用-->
            <div class="MyGold">
                <img src="@/assets/imgs/NewYear/MyGold.png" alt="">
                <div class="numberBady">
                    <div class="n_l">{{ totalData.surplusNum }}</div>
                    <div class="n_c">{{ totalData.useNum }}</div>
                    <div class="n_r">{{ totalData.totalNum }}</div>
                </div>
            </div>


            <!-- 活动商店 -->
            <div class="shop">
                <div class="tit">
                    <img src="@/assets/imgs/NewYear/shop_tit.png" alt="" />
                </div>
                <div class="shopBady">
                    <div class="item" v-for="(item, index) in getActivityData" :key="index">
                        <img src="@/assets/imgs/NewYear/shop_bady.png" alt="">
                        <div class="item_btn">
                            <img v-if="item.status == 2" src="@/assets/imgs/NewYear/shop_btn_end.png" />
                            <img v-else-if="item.status == 0" style="filter: grayscale(100%)"
                                src="@/assets/imgs/NewYear/shop_btn_get.png" alt="" />
                            <img v-else-if="item.status == 1" src="@/assets/imgs/NewYear/shop_btn_get.png"
                                @click="goShop_pop(item)" alt="" />
                        </div>

                        <div class="item_number">
                            ({{ item.buyCount ? item.buyCount : 0 }}/{{
                                item.limitCount ? item.limitCount : 0
                            }})
                        </div>

                        <div class="itemBady">
                            <div class="itemBady_t">
                                <!-- <img src="@/assets/imgs/Christmas/gold_icon.png" alt=""> -->
                                <p> &nbsp;X {{ item.goodsPrice }}</p>
                            </div>
                            <div class="itemBady_c">
                                <img :src="item.goodsImg" alt="">
                            </div>
                            <div class="itemBady_b">{{ item.goodsName }}</div>
                        </div>
                    </div>
                </div>
            </div>




        </div>
        <footerWX class="footerWX"></footerWX>

        <!-- 二次确认弹窗 -->
        <div id="mask" v-if="ispopUp">
            <div style="
            position: fixed;
            top: 13%;
            left: 35%;
            border-radius: 10px;
            width: 30vw;
            height: 70vh;
            min-width: 600px;
            padding: 5px;
            background: linear-gradient(#d7b7ff, #f9f8ff);
            z-index: 9999;
          ">
                <popUp @ispopUp="TzpopUp" propShow="3" :DBF_goShop="DBF_goShop"></popUp>
            </div>
        </div>

        <!-- 点击红包蒙版 -->
        <div class="moneyMask" v-if="moneyMask">
            <div class='moneyMaskBody'>
                <img class="hongbao" src="https://cdn.pixabay.com/photo/2022/04/12/07/05/red-envelope-7127397_1280.png"
                    alt="">

                <div ref="moneyMaskbtn" style="height: 52px;">

                </div>

                <!-- <button > 关闭蒙层</button> -->
                <div class="text" ref="moneyMaskdata"></div>
            </div>
            <div :style="{
                'animation-duration': Math.random() * 3 + 1 + 's',
                left: Math.random() * 100 + 'vw',
            }" class="iconstyle" v-for="(n, index) in 30" :key="index">
            </div>


        </div>

        <!-- 排行榜and规则 -->
        <div class="moneyMask" v-if="isSee">
            <div class='shake'
                style="height: 500px; width: 50rem;z-index: 100; position: fixed; top: 50%; left: 50%; transform: translate(-50%, -50%);">
                <see :closeisSee="closeisSee" :isSeeIndex="isSeeIndex">
                </see>
            </div>
        </div>
    </div>
</template>

<script>
import headerWX from "@/components/Header";
import footerWX from "@/components/Footer";
import axios from "axios";
import Cookies from "js-cookie";
import popUp from "@/components/pop_up";
import see from "./compon/see.vue";

export default {
    components: {
        headerWX,
        footerWX,
        popUp,
        see
    },
    data() {
        return {
            // 每日任务 挑战任务数据
            getActivityList: {
                dayActivityConfigList: [],
                activityConfigList: []
            },
            // 元宝 剩余 总共 使用 
            totalData: {},
            // 红包 剩余 总共 使用 
            totalData2: {},
            // 商品数据
            getActivityData: [],
            ispopUp: false,
            DBF_goShop: "",

            // 点击红包蒙版
            moneyMask: false,
            // 红包领取按钮
            moneyMaskbtn: '',
            // 排行榜and规则 
            isSee: false,
            isSeeIndex: 1,
            moneyMaskbtnimg: require('@/assets/imgs/NewYear/get_btn.png')
        };
    },
    created() {
        this.init()
        window.scrollTo(0, 0);
    },
    mounted() {
    },
    methods: {
        // 返还按钮
        top_btn() {
            axios({
                method: "post",
                url: "/activity/getRechargePrize",
                headers: {
                    token: Cookies.get("wx_token"),
                },
                data: {
                    activityName: "2025spring_activity",
                    userId: Cookies.get("wx_userId"), // userId
                    platForm: "web",
                },
            })
                .then((res) => {
                    if (res.data.state === 200) {
                        this.$message.success(res.data.message);
                        this.init();
                    } else {
                        this.$message.error(res.data.message);
                    }
                })
                .catch((err) => {
                    this.$message.error(err.message);
                });
        },

        go(item) {
            if (item.btnUrl.indexOf("http") != -1) {
                window.open(item.btnUrl, "_blank");
            } else {
                this.$router.push({
                    path: item.btnUrl,
                });
            }
        },

        // 领取奖励
        receive(item) {
            axios({
                method: "post",
                url: "/activity/getActivityPrize",
                headers: {
                    token: Cookies.get("wx_token"),
                },
                data: {
                    taskId: item.actId,
                    userId: Cookies.get("wx_userId"), // userId
                    platForm: "web",
                },
            })
                .then((res) => {
                    if (res.data.state == 200) {
                        this.$message.success(res.data.message);
                        this.init();
                    } else {
                        this.$message.error(res.data.message);
                    }
                })
                .catch((err) => {
                    this.$message.error(err.message);
                });
        },
        // 确认兑换二次提示
        goShop_pop(item) {
            // 赋值要兑换的商品信息
            this.DBF_goShop = item;

            // 弹窗打开
            this.ispopUp = true;
        },

        // 关闭蒙层
        TzpopUp(msg) {
            if (msg.data == true && msg.type == 3) {
                // this.hintSucceed = true
                // this.submit()
                // console.log('关闭弹窗', this.DBF_goShop);
                this.shopBuy(this.DBF_goShop);
            } else if (msg.data == false && msg.type == 3) {
                // this.$message.info('已取消')
            }
            this.ispopUp = false;
        },
        // 商品二次确定后购买
        shopBuy(item) {
            // console.log('购买', item.goodsId);

            axios({
                method: "post",
                url: "/activity/buyActivityGoods",
                headers: {
                    token: Cookies.get("wx_token"),
                },
                data: {
                    activityName: "2025spring_activity",
                    goodsId: item.goodsId,
                    goodsNum: 1,
                    userId: Cookies.get("wx_userId"), // userId
                    platForm: "web",
                },
            })
                .then((res) => {
                    if (res.data.state == 200) {
                        // console.log(res.data);
                        this.$message.success(res.data.message);

                        this.init();
                    } else {
                        this.$message.error(res.data.message);
                    }
                })
                .catch((err) => {
                    this.$message.error(err.message);
                });
        },

        // 点击打开红包蒙版
        moneyMaskTrue() {
            if (this.totalData2.surplusNum == undefined || this.totalData2.surplusNum == 0) {
                this.$message.error('当前开红包数量不足');

            } else {

                axios({
                    method: 'post',
                    url: "/activity/doLottery",
                    headers: {
                        token: Cookies.get('wx_token'),
                    },
                    data: {
                        activityName: '2025spring_activity',
                        userId: Cookies.get('wx_userId'),      // userId
                        platForm: 'web',
                    }

                }).then((res) => {
                    if (res.data.state == 200) {
                        this.moneyMask = true;
                        setTimeout(() => {
                            this.$refs.moneyMaskdata.innerHTML = '恭喜获得' + res.data.data.num + '个金元宝';
                            this.$refs.moneyMaskbtn.innerHTML = `<img style="margin: 10px auto;"src = "${this.moneyMaskbtnimg}" alt = "" > `;
                            this.$refs.moneyMaskbtn.addEventListener('click', this.moneyMaskFalse);
                        }, 2000)

                    } else {
                        this.$message.error(res.data.message)
                    }
                }).catch(err => {
                    this.$message.error(err.message)
                })

            }

        },

        // 点击关闭红包蒙版
        moneyMaskFalse() {
            this.moneyMask = false;
            // this.moneyMaskbtn = false;
            this.$refs.moneyMaskbtn.innerHTML = ''
            // 关闭时请求最新数据
            this.init()

            // let m = function (e) { e.preventDefault(); };
            // document.body.style.overflow = '';//出现滚动条
            // document.removeEventListener("touchmove", m, { passive: true });
        },


        handleMouseEnter() {
            this.$refs.RedPacket.classList.add('shaking')
            // console.log('鼠标移入');
        },
        handleMouseLeave() {
            this.$refs.RedPacket.classList.remove('shaking')
            // console.log('鼠标移出');
        },

        // 排行榜and规则
        go_See(index) {
            this.isSee = true;
            this.isSeeIndex = index;

        },

        // 关闭排行榜
        closeisSee() {
            this.isSee = false;
            this.isDice = false;
        },

        // 初始化
        init() {
            // 每日任务 挑战任务
            axios({
                method: "post",
                url: "/activity/getActivityList",
                headers: {
                    token: Cookies.get("wx_token"),
                },
                data: {
                    activityName: "2025spring_activity",
                    userId: Cookies.get("wx_userId"), // userId
                    platForm: "web",
                },
            })
                .then((res) => {
                    if (res.data.state === 200) {
                        this.getActivityList = res.data.data;
                    } else {
                        this.$message.error(res.data.message);
                    }
                })
                .catch((err) => {
                    this.$message.error(err.message);
                });

            // 剩余累计数量 商品数据
            axios({
                method: "post",
                url: "/activity/getActivityData",
                headers: {
                    token: Cookies.get("wx_token"),
                },
                data: {
                    activityName: "2025spring_activity",
                    userId: Cookies.get("wx_userId"), // userId
                    platForm: "web",
                },
            })
                .then((res) => {
                    if (res.data.state === 200) {
                        this.totalData = res.data.data.totalData;
                        this.totalData2 = res.data.data.totalData2;
                        this.getActivityData = res.data.data.goodsInfoList;
                        // this.center_list = res.data.data.activityConfigList;
                        // this.center_list.forEach((item) => {
                        //     const a = item.prizeList[0].prizeName.split("，");
                        //     this.center_list_tit.push(a);
                        // });
                    } else {
                        this.$message.error(res.data.message);
                    }
                })
                .catch((err) => {
                    this.$message.error(err.message);
                });
        },
    },
};
</script>
<style lang="less" scoped>
.container {
    min-width: 1200px;
    overflow: hidden;
    background: #ff0000;

    .content {
        margin: 0 auto;
        max-width: 2000px;
        width: 100%;
        position: relative;
        top: -100px;

        .bg {
            width: 100%;
            position: absolute;
            // top: 0;
        }
    }

    .top {
        width: fit-content;
        margin: auto;
        position: relative;

        .topBady {
            position: absolute;
            top: 26%;
            left: 50%;
            transform: translateX(-50%);
            width: 88%;
            height: 64%;
            display: flex;

            .top_left {
                flex: 60%;

                .item {
                    height: 33%;

                    p {
                        font-size: 28px;
                        line-height: 135px;
                        text-align: center;
                        color: #ff0000;
                        font-weight: 700;

                        span {
                            font-size: 28px;
                        }
                    }
                }
            }

            .top_right {
                flex: 40%;
                display: flex;

                .top_r_l {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;

                    .item {
                        display: flex;
                        padding-left: 20px;

                        p {
                            font-size: 20px;
                            line-height: 73px;
                            color: #ff0000;
                            font-weight: 700;
                        }

                        img {
                            // width: 50px;
                            // height: 50px;
                            padding: 0 10px;
                        }
                    }
                }

                .top_r_r {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;
                }
            }
        }
    }

    // 我的新春红包
    .MyNewMoney {
        width: 80%;
        margin: 50px auto;
        position: relative;
        display: flex;
        justify-content: space-between;

        .NewYear_body {
            position: relative;

            .NewYear_item {
                position: absolute;
                top: 61%;
                left: 50%;
                transform: translateX(-50%);
                width: 75%;
                height: 22%;
                display: flex;
                align-items: center;
                justify-content: space-around;

                >div {
                    font-size: 32px;
                    font-weight: bold;
                    color: #ff0000;
                }

            }
        }

    }

    // 红包弹窗
    .RedPacket {
        position: relative;
        width: 50%;
        margin: 50px auto;
        text-align: center;

        div {
            position: absolute;
            top: 30px;
            left: 49%;
            transform: translateX(-50%);
            width: 45%;
            height: 80%;
        }

        .RedPacket_hint {
            position: absolute;
            top: 0px;
            right: -10%;
            // transform: rotateX(-180deg);
            animation: hint 1s infinite alternate;
        }

        @keyframes hint {
            0% {
                transform: translate(10px, -28px)rotateX(-180deg);
            }

            100% {
                transform: translate(-10px, 28px)rotateX(-180deg);
            }
        }

    }

    @keyframes shake {
        0% {
            transform: rotate(0deg);
        }

        25% {
            transform: rotate(-10deg);
        }

        50% {
            transform: rotate(10deg);
        }

        75% {
            transform: rotate(-10deg);
        }

        100% {
            transform: rotate(0deg);
        }
    }

    .shaking {
        animation: shake 0.5s infinite alternate;

    }



    // 挑战任务
    .upgrade {
        width: fit-content;
        position: relative;
        display: flex;
        flex-direction: column;
        margin: auto;

        .upgradeBady {
            position: absolute;
            top: 36%;
            left: 50%;
            transform: translateX(-50%);
            height: 56%;
            width: 68%;
            display: flex;

            .c_l {
                flex: 49%;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                align-items: center;

                .item {
                    font-size: 24px;
                    color: #ff0000;

                    span {
                        font-size: 24px;
                    }

                }
            }

            .c_r {
                flex: 51%;
                display: flex;

                .c_r_l {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;

                    .item {
                        display: flex;
                        padding-left: 20px;
                        flex-direction: row;
                        justify-content: center;

                        .item_one {
                            display: flex;
                            align-items: center;
                        }

                        p {
                            font-size: 20px;
                            line-height: 50px;
                            color: #ff0000;
                            font-weight: 700;
                        }

                        img {
                            width: 30px;
                            height: 30px;
                            padding: 0 10px;
                        }
                    }
                }

                .c_r_r {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;

                    .item {
                        text-align: center;
                    }
                }
            }
        }
    }

    // 剩余 总共 使用
    .MyGold {
        width: fit-content;
        margin: auto;
        position: relative;
        margin-top: 90px;

        .numberBady {
            position: absolute;
            top: 61%;
            left: 50%;
            transform: translateX(-50%);
            width: 75%;
            height: 22%;
            display: flex;
            align-items: center;
            justify-content: space-around;

            >div {
                font-size: 32px;
                font-weight: bold;
                color: #ff0000;
            }
        }
    }

    // 活动商店
    .shop {
        width: 75%;
        margin: auto;
        position: relative;
        margin-top: 100px;

        .tit {
            width: fit-content;
            height: fit-content;
            margin: auto;
            margin-bottom: 50px;
        }

        .shopBady {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            .item {
                width: 24%;
                display: flex;
                flex-direction: column;
                align-items: center;
                position: relative;

                .item_btn {
                    width: 100%;
                    text-align: center;
                    margin: 10px 0;
                }

                .item_number {
                    margin-bottom: 50px;
                    color: #fff;
                    font-size: 20px;
                    // padding-left: 88px;
                    width: 100%;
                    text-align: center;
                }

                .itemBady {
                    position: absolute;
                    top: 60px;
                    left: 0;
                    width: 100%;
                    height: 53%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    .itemBady_t {
                        width: 100%;
                        position: relative;
                        flex: 21%;
                        display: flex;
                        align-items: center;

                        img {
                            width: 40px;
                            height: 40px;
                        }

                        p {
                            width: 100px;
                            position: absolute;
                            right: 22%;
                            font-size: 18px;
                            color: red;
                        }
                    }

                    .itemBady_c {
                        flex: 58%;
                        // height: 79%;
                        width: fit-content;
                        position: relative;

                        img {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            width: 90px;
                        }
                    }

                    .itemBady_b {
                        width: 100%;
                        flex: 21%;
                        color: #a40201;
                        font-size: 15px;
                        // line-height: 50px;
                        font-weight: bold;
                        text-align: center;
                        display: flex;
                        justify-content: center;
                        flex-direction: column;
                    }
                }

            }
        }
    }

    /deep/.footerWX {
        position: relative;
    }

    // 点击红包蒙版
    .moneyMask {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.7);
        /* 半透明黑色 */
        z-index: 1000;
        /* 确保遮罩层在最上层 */

        .moneyMaskBody {
            z-index: 9999;
            width: 400px;
            // background-color: #d7b7ff;
            position: relative;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            text-align: center;

            .hongbao {
                width: 100%;
                animation: 2s spin;
            }

            @keyframes spin {
                0% {
                    transform: rotateY(0deg) scale(0);
                }

                100% {
                    transform: rotateY(180deg) scale(1);
                }
            }

            .text {
                position: absolute;
                width: 100%;
                text-align: center;
                color: #fff;
                font-size: 25px;
                top: 32%;
            }
        }

        .iconstyle {
            position: fixed;
            top: 0;
            width: 40px;
            height: 40px;
            background: url("https://cdn.pixabay.com/photo/2022/04/12/07/05/red-envelope-7127397_1280.png") no-repeat;
            background-size: 100% 100%;
            animation: move infinite linear;
            z-index: 1;
        }

        @keyframes move {
            0% {
                transform: translateY(0);
            }

            100% {
                transform: translateY(100vh);
            }
        }

    }
}
</style>
